<template>
    <div class="orgDataStatis">
        <div style="text-align:left;">
            <el-date-picker
            style=" margin-left:30px;"
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd "
            value-format="yyyy-MM-dd">
            </el-date-picker>
            <button @click="searchData">搜索</button>
            <button @click="reset">重置</button>
        </div>
        <div class="statisList flex alignCenter spacebetween">
            <div class="statis">
                <p class="statisTitle">
                    成员数量
                </p>
                <span class="c33">{{organCensus.member_count}}</span>
            </div>
            <div class="statis">
                <p class="statisTitle">
                    成员观看课程总时长 
                </p>
                <span class="c33">{{organCensus.time_long}}</span>
            </div>
            <div class="statis">
                <p class="statisTitle">
                    成员观看总课程数量 
                </p>
                <span class="c33">{{organCensus.course_count}}</span>
            </div>
            <div class="statis">
                <p class="statisTitle">
                    成员总积分
                </p>
                <span class="c33">{{organCensus.point_all}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { organ_census } from '@/utils/Api/userList'
export default {
    name:'orgDataStatis',
    data(){
        return{
            value1:'',
            organCensus:{}
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(){
            let res = await organ_census(this.organCensus)
            console.log(res)
            this.organCensus = res
        },
        searchData(){
            this.initial()
        },
        reset(){
            this.value1 = ''
            this.organCensus = {}
            this.initial()
        }
    },
    watch:{
        value1(){
            console.log(this.value1)
            if(this.value1){
                this.organCensus.start_time = this.value1[0]
                this.organCensus.end_time = this.value1[1]
            }
        }
    }
}
</script>
<style lang="scss" scoped>
button{
    font-size: 16px;
    color: #fff;
    padding: 11px 20px;
    background: #47D7E3;
    margin-left: 9px;
    cursor: pointer;
}
.kcpf{
    width: 342px;
    height: 111px;
    opacity: 1;
    background: #f7f7f7;
    font-size: 15px;
    color: #797979;
    p{
        display: inline-block;
        margin-left: 43px;
    }
}
.tables{margin-top: 27px;width: 98%;
float: right;}
.table1, .table2{
    text-align: left;
    width: 354px;
    font-size: 16px;
    color: #242424;
    p{font-weight: bold;}
}
.table2{width: 542px;}
td{
    border-bottom: 1px dashed #D8D8D8!important;
}
.orgDataStatis{
    .statisList{
        width: 98%;
        margin-top: 37px;
        float: right;
        .statis{
            width: 193px;
            height: 130px;
            overflow: hidden;
            background: #f7f7f7;
            color: #717171;
            padding-left: 20px;
            text-align: left;
			position: relative;
            &:nth-child(2){
                .statisTitle::before{background: #FDB24C!important;}
            }
            &:nth-child(3){
                .statisTitle::before{background: #FD576A!important;}
            }
            &:nth-child(4){
                .statisTitle::before{background: #9E4CFD!important;}
            }
            a{
                font-size: 14px;
                color: #1782FF;
                display: inline-block;
                margin-top: 15px;
                margin-left: 18px;
                width: 100%;
            }
            span{
                font-size: 22px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				display: flex;
				align-items: center;
				justify-content: space-around;
                // display: inline-block;
                // margin-left: 18px;
                // margin-top: 12px;
                // text-align: left;
				
            }
            .statisTitle{
                font-size: 15px;
                margin-top: 20px;
                position: relative;
                &::before{
                    content: '';
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: #47D7E3;
                    left: 0px;
                    top: 0;
                }
            }
        }
    }
}
</style>